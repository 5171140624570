<template>
  <div class="zy-layout-column">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "ZyLayoutColumn",
  props: {
    //宽度，非必填
    width: Number,
    animation: {
      // left, right, center
      type: String,
      default: "left",
    },
  },
}
</script>

<style lang="less" scoped>
.zy-layout-column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
